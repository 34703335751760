<template>
  <div class="intelligence">
    <component :is="curr_view" ></component>
    

  </div>
</template>

<script>
import newax400 from '@/components/ax400-rk200/newax400/index.vue'
export default {
    components:{
      newax400
    },
    data() {
      return {
        lis:[
          {name:'newax400',opt:1,avatar:require('../../../assets/image/ax400_avator.png'),code:'newax400'},
          // {name:'RK200',opt:2,avatar:require('../../../assets/image/rk200_avator.png'),code:'RK200'}
        ],
        curr_view:'newax400'

      }
    },
    methods:{
      
    }
}

</script>

<style lang="less">
  @import '~@/assets/style/chat/ax400.less';
</style>