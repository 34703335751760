<template>
  <div class="chat-container">
    <div class="record-container">
        <div class="new-chat" @click="handleTalk('1')">
          <span>新建聊天</span>
          <i class="el-icon-circle-plus-outline"></i>
        </div>
        <!-- 聊天记录 -->
        <div class="record-list">
          <div class="record-item" v-for="(item,index) in historyLis" :key="'record'+index">
            <span>{{ getRecordDate(item[0].date) }}</span>
            <div class="record-p" v-for="v in item" :key="'record-item'+v.id">
              <p  :class="[{'curr-select-record':currHistoryItem.id === v.id}]" @click="handleSession(v)">
                {{v.question}}
              </p>
              <i class="del-record el-icon-delete" @click.stop="delRecord(v.id)"></i>
            </div>
            
          </div>
        </div>
    </div>
    <div class="mind-container">
        <div class="title">
          新AX400 {{ statusText }}
          <div class="ax-version">
            <el-select v-model="axVersion" placeholder="请选择版本号">
              <el-option
                v-for="item in versionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="connect-box">
          <div class="connect-lis" ref="lis">
            <div class="connect-main">
                <div class="chat-time">
                  <span>{{ chat_time }}</span>
                  <span>AX400为您服务</span>
                </div>

                <div class="chat-user" v-for="(v, i) in messageLis" :key="'message' + i">
                  <div class="other-user user-box" v-if="v.type == 'assistant'">
                    <div class="user-avator">
                      <img src="../../../assets/image/ax400_avator.png" alt="" />
                    </div>
                    <div class="user-text" v-if="v.messageTxt">
                      <div v-if="v.is_type">
                        <!--  :contentType="'html'" [item]  v-for="(item,index) in v.messageTag"
                          :key="'typed'+index"-->
                        <vue-typed-js
                          :strings="[item]"
                          :typeSpeed="10"
                          :contentType="'html'"
                           v-for="(item,index) in v.messageTag"
                          :key="'typed'+index"
                          @onComplete="doSmth()"
                          @onTypingPaused="endDosmth()"
                          @onStop="stopSmth()"
                          :showCursor="false"
                        >
                          <span :class="[{ typing: v.is_type }]"></span>
                          <!-- <span class="typing"></span> -->
                        </vue-typed-js>
                      </div>
                      <span v-if="!v.is_type" v-html="v.messageTxt"></span>
                    </div>
                  </div>

                  <div class="my-user user-box" v-else>
                    <div class="user-text" v-html="v.messageTxt"></div>
                    <div class="user-avator">
                      <img :src="v.avator" alt="" v-if="v.avator" />
                      <span v-else>{{ lastName(v.name) }}</span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="connect-send">
            <div class="send-main">
              <div class="send-box">
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="manual"
                  content="不能发送空白信息"
                  v-model="sendVisible">
                  <el-input
                    slot="reference"
                    class="connect-msg"
                    :disabled="!is_end"
                    v-model="messageTxt"
                    placeholder="请输入内容,可用Enter发送"
                    @keydown.enter.native="handleKeyCode($event)"
                  >
                  </el-input>
                </el-popover>
                
                <div class="send-btn">
                  <el-button type="primary" :disabled="!is_end" @click="handleSendText">发送</el-button>
                </div>
              </div>
              <div class="send-switch">
                  <el-switch
                    v-model="sendSwitch"
                    active-text="单句提问"
                    active-color="#13ce66"
                    inactive-text="">
                  </el-switch>
              </div>
            </div>
            
            <p>新AX400可能会犯错误，请考虑核实重要信息。</p>
            
            <!-- 新开话题，继续此话题 -->
            <!-- <div class="subject-talk" v-if="!is_end">
              <div class="btn new-btn" @click="handleTalk('1')">新开话题</div>
              <div class="btn continue-btn" @click="handleTalk('0')">继续此话题</div>
            </div> -->
          </div>
        </div>
      </div>
  </div>

  
</template>
  <script>
import { askAx4002, getAx4002Receice,  getTitleHistoryLis, getHistorySessionLis, delAx4002Title } from '@/service/help';
import { dateFormat, filterArr } from '@/utils/utils';
import MarkdownIt from 'markdown-it'
import mdKatex from '@traptitech/markdown-it-katex'
import hljs from 'highlight.js';
import Clipboard from 'clipboard'
import { Message } from 'element-ui';

let codeTagId = 0 // 代码块id
function highlightBlock(str, lang) {
  return `<pre class="pre-box">
              <div class="code-header">
                <span >${lang}</span>
                <span id="copy-btn${codeTagId}" data-clipboard-target="#code${codeTagId}" onclick="getCopy('copy-btn${codeTagId}')">复制代码</span>
              </div>
              <code class="hljs ${lang}" id="code${codeTagId}">${str}</code>
          </pre>
          `
}

export default {
  data() {
    return {
      ns: '0', // 0继续 1新话题
      chat_time: dateFormat('hh:mm'),
      messageLis: [],
      messageTxt: '',
      is_end: true,
      message_end: true,
      handleFlag: false,
      s: '',
      start: '',
      mdi:'',
      codeLanguage:'',
      isContinueReceive:false, // 是否继续接收代码信息
      codeStr:'', // 接收到的代码块字符串
      sendVisible:false, // 空白信息弹窗，
      sendSwitch:true, // 提问开关
      axVersion:'AX400版本4.0', // ax400版本号
      versionOptions:[
        {label:'AX400版本2.0',value:'AX400版本2.0'},
        {label:'AX400版本3.0',value:'AX400版本3.0'},
        {label:'AX400版本4.0',value:'AX400版本4.0'}
      ],
      // 历史记录列表
      historyLis:[],
      currHistoryItem:{
        id:0
      }, // 当前的聊天会话对象
      requestid:"" // 会话请求id
    };
  },
  components: {},
  created() {
    console.log(this.userInfo, 'user');
    const that = this
    that.getHistoryLis()
    // this.mdi.use(mdKatex, { blockClass: 'katexmath-block rounded-md p-[10px]', errorColor: ' #cc0000' })
    // getAX400Start()
    // this.messageLis = sessionStorage.getItem('messageLis') ? JSON.parse(sessionStorage.getItem('messageLis')) : [];
    // if (this.messageLis.length > 0) {
    //   this.messageLis.forEach(v => (v.is_type = false));
    //   codeTagId = this.messageLis.length
    // }
    


    this.mdi = new MarkdownIt({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      highlight(code, language) {
        console.log(language,'====lalalalalal=========')
        that.codeLanguage = language ? language : ''
        codeTagId ++
        // const validLang = !!(language && hljs.getLanguage(language))
        // if (validLang) {
        //   const lang = language ?? ''
        //   return highlightBlock(hljs.highlight(lang, code, true).value, lang)
        // }
        return highlightBlock(hljs.highlightAuto(code).value, that.codeLanguage)
      }
    })


    window.getCopy = function(btnId){
          console.log(btnId)
          let clipboard = new Clipboard("#"+btnId)
          // 复制成功
          clipboard.on("success", (e) => {
            console.log(e, '复制成功');
            Message({
                type: 'success',
                message: '复制成功!',
            });
            clipboard.destroy();
          });
          // //复制失败
          clipboard.on("error", (e) => {
            console.log(e, '复制失败');
            Message({
                type: 'error',
                message: '复制失败',
            });
            clipboard.destroy();
          });

    }

    // console.log(this.messageLis, 'that.messageLis===');
    // console.log(/^```.*```$/gm.test('```javascript  ```\n\n'));
  },
  watch: {
    is_end(newVal, oldVal) {
      
    },
    isContinueReceive(newVal, oldVal){
      console.log('看看这里的值isContinueReceive=',newVal)
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    statusText() {
      return !this.message_end ? '正在输入...' : '';
    }
  },
  methods: {
    getHtmlCode(codeStr){ // 历史记录的回答也需要显示标签
    console.log('这里是重新渲染html')
      return this.mdi.render(codeStr)
    },
    delRecord(id){ // 删除历史记录
      this.$confirm('确认要删除该这条历史记录吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(() => {
          delAx4002Title({id:[id]}).then(res=>{
            if(!res.data.success){
              this.$message({
                  type: 'error',
                  message: res.data.msg,
              });
            }
            this.$message({
                type: 'success',
                message: '删除成功!',
            });
            
            this.getHistoryLis()
          })
        })
        .catch(() => {
          
        });
    },
    handleSession(item){ // 切换会话
      this.currHistoryItem = item
      this.getHisSessionLis(item.id)
    },
    getRecordDate(date){
				let time_str = date;
				if (new Date(date).getDate() === new Date().getDate()) {
					 time_str = "今天"+`(${date})`;
				} else if (new Date(date).getDate() === (new Date().getDate() - 1)) {
						time_str = "昨天"+`(${date})`;
				}
			return time_str;
		
    },
    getHistoryLis(){
      getTitleHistoryLis().then(res=>{
        console.log(res)
        if(!res.data.success){
          this.$message({
              type: 'error',
              message: res.data.msg,
          });
        }
        let monthArr = res.data.data.map(v=> ({...v,date:dateFormat('YY-MM-DD',new Date(v.ctime))}))
        let last_monthArr = filterArr(monthArr,'date')
        console.log(last_monthArr,'=last_monthArr')
        this.historyLis = last_monthArr
        if(this.ns === '0' && this.historyLis.length > 0){ // 继续话题需要默认第一个，新建话题则需要当前的那个
          this.currHistoryItem = last_monthArr[0][0]
          this.getHisSessionLis(this.currHistoryItem.id)
        }
        if(this.ns === '0' && this.historyLis.length === 0){ // 当历史记录都没了，默认新建状态
          this.handleTalk('1')
        }
       
        
      })
    },
    getHisSessionLis(id){ // 获取单个会话框历史记录
      getHistorySessionLis({converid:id}).then(res=>{
        let { data } = res.data
        this.messageLis = []
        let isAsk = false // 是否重新执行询问
        // 判断整个会话是否有未完成回复,有则重新生成答案
        for(let i=0;i<data.length;i++){
          if(data[i].isdone === 0 && data[i].role === 'user'){
              this.messageTxt = data[i].content
              isAsk = true
              break;
          }
          this.messageLis.push({...data[i],is_type:false,type:data[i].role,messageTxt:this.getHtmlCode(data[i].content), name: this.userInfo.name,
            avator:
              this.userInfo.avatar == 'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png'
                ? ''
                : this.userInfo.avatar,})
        }
        codeTagId = this.messageLis.length
        this.message_end = true
        this.is_end = true
        if(isAsk){ // 重新发起提问
          this.handleSendText()
        }else{
          this.setLisScrollTop();
        }
        
      })
    },
    countOccurrences(str, charToCount) {
      // 使用正则表达式，g标志表示全局匹配
      const regex = new RegExp(charToCount, 'g');
      // 使用match方法计算匹配到的个数
      const matches = str.match(regex);
      return matches ? matches.length : 0;
    },
    stopSmth() {
      console.log('停下来之后');
    },
    endDosmth() {
      // 打字已停止
      console.log('打字已停止');
    },
    doSmth() {
      // 打字完成之后
      console.log('打字完成之后');
      if(!this.isContinueReceive){
        this.ReceiveText();
      }
      
    },
    initAns(data) {
      let send_data = ''
      this.codeStr += data
      // data = data.replace(/&/g, '&amp;');
      // data = data.replace(/</g, '&lt;');
      // data = data.replace(/>/g, '&gt;');
      // data = data.replace(/`/g, '&#96;');
      // data = data.replace(/\n/g, '<br>');
      
      if(this.countOccurrences(this.codeStr,'```')%2 !== 0 ){ // 奇数 继续接收信息，直接出现偶数再呈现到html上
        this.isContinueReceive = true
        
      }else{ // 偶数 说明是完整的代码块
        send_data = this.mdi.render(this.codeStr)
        this.codeStr = '' // 完整代码块需要 清空缓存  可能多个代码块，或者包含其他文本
        this.isContinueReceive = false
      }

      if(this.countOccurrences(this.codeStr,'```') === 0){ // 一个也没检测到  是普通文本没有代码块   默认呈现在html上
        this.isContinueReceive = false
      }

      
      
      return send_data;
    },
    resetTalk() {
      // 开启新话题
      this.chat_time = dateFormat('hh:mm');
      this.messageLis = [];
      this.currHistoryItem.id = 0
      // sessionStorage.setItem('messageLis', '');
    },
    handleTalk(ns) {
      this.ns = ns;
      this.is_end = true;
      if (this.ns == '1') this.resetTalk();
    },
    handleKeyCode(e) {
      console.log(e, '==event=');
      if (e.ctrlKey && e.keyCode == 13) {
        //用户点击了ctrl+enter触发
        this.messageTxt += '\n';
      } else {
        e.preventDefault(); // 阻止浏览器默认换行操作
        //用户点击了enter触发
        this.handleSendText();
      }
    },
    handleSendText() { // 发起提问
      console.log(this.messageTxt, 'this.messageTxt');
      const that = this
      if(this.sendVisible) return
      if(!this.messageTxt){
        this.sendVisible = true
        let timer = setTimeout(()=>{
          clearTimeout(timer)
          that.sendVisible = false
        },2000)
        return
      }
      if (this.handleFlag) return;
      this.handleFlag = true;
      askAx4002({
        model:"gpt-3.5-turbo",
	      messages : [{ role:"user",content:this.messageTxt}],
        converid : this.currHistoryItem.id,
        stream: true
      }).then(res => {
        console.log(res);
        this.handleFlag = false;
        let { success, data } = res.data;
        if (success) {
          this.messageLis.push({
            type: 'user',
            messageTxt: this.messageTxt,
            name: this.userInfo.name,
            avator:
              this.userInfo.avatar == 'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png'
                ? ''
                : this.userInfo.avatar,
          });
          this.messageTxt = '';
          this.is_end = false;
          this.message_end = false;
          if(!this.currHistoryItem.id){ // 新建会话需要刷新历史记录列表
             if(this.historyLis.length === 0){ // 没有历史记录时，此时为新建状态
                this.ns = '1'
             }
             this.getHistoryLis()
          }
          this.requestid = data.requestid
          this.currHistoryItem.id = data.converid
          this.setLisScrollTop();
          this.ReceiveText();
        }
      });
    },
    ReceiveText() {
      console.log('接口信息-----')
      const that = this;
      let timer = setTimeout(() => {
        getAx4002Receice({
          requestID: this.requestid
        }).then(res => {
          console.log(res);
          let { success, data } = res.data;
          if (success) {
            that.message_end = data.done;
            

            if (data.content) {
              let ans = that.initAns(data.content);
              if(that.isContinueReceive){ // 直到有完整的代码块再到下一步
                that.ReceiveText()
                return
              }
              if (that.messageLis[that.messageLis.length - 1].type == 'user') {
                that.messageLis.push({
                  type: 'assistant',
                  messageTxt: ans,
                  messageTag: [ans],
                  is_type: true,
                });
              } else {
                that.messageLis[that.messageLis.length - 1].messageTxt = that.messageLis[that.messageLis.length - 1].messageTxt  +ans
                  // that.messageLis[that.messageLis.length - 1].messageTxt + ans;
                that.messageLis[that.messageLis.length - 1].messageTag.push(ans);
              }
              //   that.messageLis[that.messageLis.length - 1].messageTxt = that.messageLis[that.messageLis.length - 1].messageTxt.replace(/^```.*```$/gm,`&lt;highlightjs  code=" ${that.messageLis[that.messageLis.length - 1].messageTxt} " &gt;&lt;&#47highlightjs&gt;`)
              console.log(
                that.messageLis[that.messageLis.length - 1].messageTxt,that.messageLis[that.messageLis.length - 1].messageTag,
                '====that.messageLis[that.messageLis.length - 1].messageTxt==',
              );
              // sessionStorage.setItem('messageLis', JSON.stringify(that.messageLis));

              if (data.done) {
                clearTimeout(timer);
                console.log('这里执行了吗');
              }
            } else {
              if (data.done) {
                clearTimeout(timer);
                console.log('这里执行了吗');
                
              }
            }

            // 最后接收数据判断是否结束
            if(data.done){ // 结束后默认继续话题
              this.handleTalk('0')
            }
            this.setLisScrollTop();
          }
        });
      }, 500);
    },
    setLisScrollTop() {
      this.$nextTick(() => {
        this.$refs.lis.scrollTop = this.$refs.lis.scrollHeight;
      });
    },
  },
};
</script>
<style lang="less">
@import '~@/assets/style/chat/ax400.less';

#container {
  color: white;
    font-size: 2em;
    width: 21em;
    height: 1.5em;
    border-right: 1px solid transparent;
    animation: typing 2s steps(42, end), blink-caret .75s step-end infinite;
    font-family: Consolas, Monaco;
    word-break: break-all;
    overflow: hidden;
}


</style>

<style scoped>
/* 打印效果 */
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 21em;
    }
}
/* 光标 */
@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: currentColor;
    }
}
</style>